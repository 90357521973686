import { Module, GetterTree, MutationTree, ActionTree } from "vuex";
import { RootState } from "@/types/state";
import ControlService from "@/services/ControlService";
import WorkOrderControlState from "@/types/controls/workOrder";

const namespaced = true;
const controlService = new ControlService();

export const state: WorkOrderControlState = {
  control_id: "",
  scheduling_group_items: [],
  prevent_excess_completions: "",
  end_Job_Stock_Transfer_Mode: "",
  oper_time_incr: "1",
};

export const getters: GetterTree<WorkOrderControlState, RootState> = {
  getSchedulingGroupItems: (state) => {
    return state.scheduling_group_items;
  },
  getPreventExcessCompletions(state) {
    return state.prevent_excess_completions == "Y";
  },
  getEndJobStockTransferMode: (state) => {
    return state.end_Job_Stock_Transfer_Mode;
  },
  getCalendarTimeIncrement: (state) => {
    return parseInt(state.oper_time_incr);
  },
};

export const mutations: MutationTree<WorkOrderControlState> = {
  SET_DATA(state, workOrderControl: WorkOrderControlState) {
    state.scheduling_group_items =
      workOrderControl.scheduling_group_items || [];
    state.control_id = workOrderControl.control_id;
  },
  SET_PREVENT_EXCESS_COMPLETIONS(state, value) {
    state.prevent_excess_completions = value;
  },
  SET_END_JOB_STOCK_TRANSFER_MODE(state, end_Job_Stock_Transfer_Mode) {
    state.end_Job_Stock_Transfer_Mode = end_Job_Stock_Transfer_Mode;
  },
  SET_OPERATION_TIME_INCREMENT(state, value) {
    state.oper_time_incr = value;
  },
};

export const actions: ActionTree<WorkOrderControlState, RootState> = {
  async fetchWorkOrderControl({ commit, state }) {
    return new Promise((resolve, reject) => {
      if (state.control_id) {
        resolve(state);
      } else {
        controlService
          .getControl("WO", "WO.CONTROL", "CONTROL", "")
          .then((response: any) => {
            if (response.length > 0) {
              commit("SET_DATA", response[0]);
              commit(
                "SET_PREVENT_EXCESS_COMPLETIONS",
                response[0].prevent_excess_completions || "",
              );
              commit(
                "SET_END_JOB_STOCK_TRANSFER_MODE",
                response[0].end_job_stock_transfer_mode || "",
              );
              commit(
                "SET_OPERATION_TIME_INCREMENT",
                response[0].oper_time_incr || "1",
              );
              resolve(response[0]);
            } else {
              reject("No data returned");
            }
          })
          .catch((error) => {
            reject(error);
          });
      }
    });
  },
};

export const woControl: Module<WorkOrderControlState, RootState> = {
  namespaced,
  getters,
  state,
  mutations,
  actions,
};
