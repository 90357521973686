import { Module, GetterTree, MutationTree, ActionTree } from "vuex";
import { RootState } from "@/types/state";
import SessionState, { Scan } from "@/types/state/session";
import TokenService from "@/services/TokenService";

const tokenService = new TokenService(process.env.VUE_APP_AZURE_FUNC_URL);
const namespaced = true;

export const state: SessionState = {
  user: null,
  client: null,
  loginUrl: null,
  loading: false,
  token: null,
  subKey: null,
  tokenTimeStamp: null,
  sessionStartDateStamp: null,
  roverJwt: null,
  tokenFetchInProgress: null,
  userTimeZone: null,
};

const hasCommand = (path: string, commands: Array<string> | undefined) => {
  if (commands?.find((item) => item == path)) {
    return true;
  }
  return false;
};

const scanapps = [
  "INV.Q",
  "PICKER.E",
  "IT.BE",
  "PHYS.BE",
  "PHYS.BE2",
  "ITSO.BE",
  "EMPTIME.BE3",
  "EMPTIME.BE4",
  "SHIP.BE",
  "SHIP.CBE",
  "SHIP.CBE2",
];

export const getters: GetterTree<SessionState, RootState> = {
  getUser(state): any {
    return state.user;
  },
  getFilter: (state) => (file_name: string, field_name: string) => {
    if (state.user?.attributes) {
      let list = state.user?.attributes.filter(
        (item: any) =>
          item.file_name == file_name && item.field_name == field_name,
      );
      list = list.flatMap((item: any) => item.value.split("|"));
      list = list.map((item: any) => item.trim()).filter((item: any) => item);
      return list;
    }
    return [];
  },
  getUserTimeZone(state): any {
    return state.userTimeZone;
  },
  getWebAccess: (state) => (menu: string) => {
    return hasCommand(menu, state.user?.commands);
  },
  getUserId(state): string {
    return state.user?.user_id as string;
  },
  getLogin(state): any {
    return state.loginUrl;
  },
  getClient(state): any {
    return state.client;
  },
  getToken(state): any {
    return state.token;
  },
  getSubKey(state): any {
    return state.subKey;
  },
  getRoverJwt(state): any {
    return state.roverJwt;
  },
  getTokenTimeStamp(state): any {
    return state.tokenTimeStamp;
  },
  getSessionStartDateStamp(state): any {
    return state.sessionStartDateStamp;
  },
  getAccess: (state) => (path: string) => {
    const pathList = path.split("/");
    const commands = state.user?.commands || [];
    let webName = "";
    switch (pathList[1]) {
      case "dashboard":
        webName = "DASHBOARD";
        break;
      case "cash":
        webName = "CASH.E";
        break;
      case "accounts":
      case "accounting":
        webName = "ACCOUNTING";
        if(pathList[2] == "accounts-payable") {
          webName = "AP.E";
        } else if(pathList[2] == "accounts-receivable") {
          webName = "AR.E";
        } else if(pathList[2] == "gl")
          webName = "GLCHART.E";
        break;
      case "invoices":
        webName = "AR.E";
        break;
      case "deal-sheet":
        webName = "DEALSHEET";
        break;
      case "documentControl":
        webName = "DOC";
        break;
      case "engineering":
        webName = "ENG";
        break;
      case "fieldService":
        webName = "FEILD";
        break;
      case "generalLedger":
        webName = "LEDGER";
        break;
      case "manufacturing":
        webName = "MAN";
        break;
      case "marketingAndSales":
        webName = "MARKETING";
        break;
      case "production":
        webName = "WO.E";
        break;
      case "projects":
        webName = "PROJ";
        break;
      case "workFlows":
        webName = "WORKFLOW";
        break;
      case "purchasingReceiving":
        webName = "PURCH";
        break;
      case "customers":
        webName = "CUST.Q";
        break;
      case "tickets":
        webName = "TICKET.E";
        break;
      case "inventory":
        webName = "INVENTORY";
        break;
      case "receipts":
        webName = "RECEIPTS.E";
        break;
      case "sales":
        if (pathList[2] == "orders") {
          return (
            hasCommand("SO.E", state.user?.commands) ||
            hasCommand("PSO.E", state.user?.commands)
          );
        } else if (pathList[2] == "quotes") {
          webName = "SOQUOTE.E";
        } else if (pathList[2] == "opportunities") webName = "SALEOPP.E";
        else {
          return (
            commands.filter((item) =>
              ["SO.E", "SOQUOTE.E", "SALEOPP.E"].includes(item),
            ).length > 0
          );
        }
        break;
      case "scan":
        if (pathList[2] == "inventory") webName = "INV.Q";
        else if (pathList[2] == "picking") webName = "PICKER.E";
        else if (pathList[2] == "transfer") webName = "IT.BE";
        else if (pathList[2] == "shopTransaction") webName = "ST.BE";
        else if (pathList[2] == "count") webName = "PHYS.BE";
        else if (pathList[2] == "recount") webName = "PHYS.BE2";
        else if (pathList[2] == "order") webName = "ITSO.BE";
        else if (pathList[2] == "startJob") webName = "EMPTIME.BE3";
        else if (pathList[2] == "endJob") webName = "EMPTIME.BE4";
        else if (pathList[2] == "shipment") webName = "SHIP.BE";
        else if (pathList[2] == "pick") webName = "SHIP.CBE";
        else if (pathList[2] == "pack") webName = "SHIP.CBE2";
        else if (pathList[2] == "woCompletion") webName = "ST.E3";
        else
          return commands.filter((item) => scanapps.includes(item)).length > 0;
        break;
      case "parts":
        webName = "PARTS.E";
        break;
      case "pos":
        webName = "PSO.E";
        if(pathList[2] == "invoices") {
          webName = "AR.E"
        } else if(pathList[2] == "receipt-history") {
          webName = "CASH.E"
        } else if(pathList[2] == "pof")
          webName = "CASH.E";
        else if(pathList[2] == "payment") {
          webName = "CASH.E"
        }
        break;
      case "fieldservices":
        webName = "FSO.E";
        break;
      default:
        return true;
    }

    return hasCommand(webName, state.user?.commands);
  },
};

export const mutations: MutationTree<SessionState> = {
  //to change the state
  DO_LOGIN(state, user) {
    state.user = user;
  },
  DO_LOGOUT(state) {
    state.user = null;
    state.client = null;
    state.loading = false;
  },
  SET_CLIENT_NAME(state, client) {
    state.client = client;
  },
  SET_LOGIN_URL(state, url) {
    state.loginUrl = url;
  },
  SET_TOKEN(state, token) {
    state.token = token;
  },
  SET_SUB_KEY(state, subKey) {
    state.subKey = subKey;
  },
  SET_ROVER_JWT(state, roverJwt) {
    state.roverJwt = roverJwt;
  },
  SET_TOKEN_TIMESTAMP(state, timer) {
    state.tokenTimeStamp = timer;
  },
  SET_SESSION_START_DATESTAMP(state, date) {
    state.sessionStartDateStamp = date;
  },
  SET_TOKEN_FETCH_IN_PROGRESS(state, promise) {
    state.tokenFetchInProgress = promise;
  },
  CLEAR_TOKEN_FETCH_IN_PROGRESS(state) {
    state.tokenFetchInProgress = null;
  },
  SET_USER_TIMEZONE(state, timezone) {
    state.userTimeZone = timezone;
  },
};

export const actions: ActionTree<SessionState, RootState> = {
  logout({ state, commit, dispatch }) {
    dispatch("pos/clearRegister", null, { root: true });
    commit("DO_LOGOUT");
    window.sessionStorage.removeItem("vuex");
    if (state.loginUrl) {
      location.replace(state.loginUrl.toString());
    }
  },
  setSessionStartDate({ commit }, date) {
    commit("SET_SESSION_START_DATESTAMP", date);
  },
  calculateUserTimeZone({ state, commit }) {
    const userDate = new Date().toLocaleString("en-US");
    const userHour = new Date(userDate).getHours();

    let greeting = "";
    if (userHour >= 0 && userHour < 5) {
      greeting = "Nice to see you again";
    } else if (userHour < 12) {
      greeting = "Good Morning";
    } else if (userHour < 18) {
      greeting = "Good Afternoon";
    } else {
      greeting = "Good Evening";
    }

    commit("SET_USER_TIMEZONE", greeting);
  },
  async fetchToken({ state, commit, dispatch }) {
    const now = Math.round(Date.now() / 1000);
    const timeStamp = state.tokenTimeStamp || 0;
    const expiry = (state.token?.expires_in || 0) - 5 * 60;
    if (timeStamp + expiry < now) {
      try {
        // If token fetch is already in progress, just wait for it.
        if (state.tokenFetchInProgress) {
          try {
            const response = await state.tokenFetchInProgress;
            return response.access_token;
          } catch (error) {
            throw { success: false, error: error };
          }
        }

        const fetchInProgress = tokenService.fetchToken();
        commit("SET_TOKEN_FETCH_IN_PROGRESS", fetchInProgress);

        const response = await fetchInProgress;
        commit("CLEAR_TOKEN_FETCH_IN_PROGRESS");

        if (!response.error) {
          commit("SET_TOKEN", response);
          commit("SET_TOKEN_TIMESTAMP", now);
          return response.access_token;
        } else {
          dispatch(
            "notification/addError",
            "Authentication error. Please contact your administrator",
            { root: true },
          );
        }
      } catch (error) {
        dispatch("notification/addError", `An error has occured: ${error}`, {
          root: true,
        });
        throw { success: false, error: error };
      }
    } else {
      return state.token?.access_token;
    }
  },
};

export const session: Module<SessionState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
